import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { ConfigProvider, Row, Col, List, Avatar, Icon, Menu, Popover } from 'antd/lib';
import { StyledImageContainer, StyledImage, StyledExpandedHeader, StyledInitialName } from './styles';
import LogoIcon from '../../public/cropwise-logo-border.svg';
import NideraLogo from '../../public/nidera_logo.png';
import HomeIcon from '../../public/Home-icon.svg';
import OrganizationTabImg from '../../public/organization-tab.svg';
import HelpIcon from '../../public/help-icon.svg';
import { LanguageDropdown } from '../../localization/LanguageDropdown';
import { iff } from '../../utils/iff';
import { getInitialName } from '../../utils/common-methods';


import styled from 'styled-components';
let isSessionId;
const StyledSpan = styled.span`
  font-family: ${props => get(props, 'theme.font.family')};
`;
const StyledAnchorTag = styled.a`
  color: #000;
  &:hover {
    color: ${props => get(props, 'theme.colors.backArrowBgColor')} !important;
  }
`;
const analyticsdata = [
  {
    title: 'Lawrences Group',
    description: 'Organization',
  },
];

export const NewHeader = props => {
  useEffect(() => {
    isSessionId = localStorage.getItem('session_id');
  }, []);
  const { t } = useTranslation();

  const Usermenu = (
    <Menu
      style={{ background: '#F5F8FC', fontWeight: 500 }}
      className="popupmenuList"
    >
      <Menu.Item
        key="2"
        title="Supported Language"
        style={{ padding: ' 0 10px' }}
      >
        <LanguageDropdown type="FLAT" />
      </Menu.Item>
      <Menu.Item
        key="3"
        title="Logout"
        onClick={() => props.handleLogout()}
        style={{ padding: ' 0 10px' }}
      >
        <StyledAnchorTag className="logoutLink">
          <Icon type="poweroff" />
          <StyledSpan>{t('Logout')}</StyledSpan>
        </StyledAnchorTag>
      </Menu.Item>
    </Menu>
  );
  const style = { background: 'transparent', padding: '0 0' };
  const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <img src={HelpIcon} style={{ fontSize: 20 }} />
      <p>{t('Data Not Found')}</p>
    </div>
  );
  const propertiesEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <img src={HelpIcon} style={{ fontSize: 20 }} />
      <h4>{t('Oh no...')}</h4>
      <p>{t('There are no properties for this organization')}</p>
    </div>
  );

  return (
    <StyledExpandedHeader>
      <Row>
        <Col className="gutter-row" span={2}>
          <StyledImageContainer>
            <StyledImage
              width="60px"
              height="38px"
              src={isSessionId ? NideraLogo : LogoIcon}
              cursor="pointer"
              onClick={() => props.onLogoClick()}
            />
          </StyledImageContainer>
        </Col>
        <Col className="gutter-row" span={22}>
          <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 8 }}>
            <Col className="gutter-row" span={5}>
              <div style={style}>
                <ConfigProvider renderEmpty={propertiesEmpty}>
                  <List
                    header={
                      <div style={{ fontSize: 13 }}>{t('Properties')}</div>
                    }
                    className="propertiesList"
                    itemLayout="horizontal"
                    dataSource={props.properties}
                    renderItem={item => (
                      <List.Item
                        style={{
                          opacity: iff(item.id === props.propertyId, 1, 0.4),
                          cursor: 'pointer',
                        }}
                        data-testid={`property-in-header-container-${item.name.replaceAll(" ", "-")}`}
                        onClick={() =>
                          props.navigateByPropertyId(props.orgId, item.id)}
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              src={isSessionId ? HomeIcon : OrganizationTabImg}
                            />
                          }
                          description={item.description}
                          title={
                            <StyledSpan
                              style={{
                                display: 'block',
                                width: '150px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {item.name}
                            </StyledSpan>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </ConfigProvider>
              </div>
            </Col>
            <Col className="gutter-row" span={19}>
              <div style={style}>
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                  <List
                    grid={{ gutter: 21, column: 3 }}
                    header={
                      <ul
                        className="rightMenuUl"
                        style={{ fontSize: 14, fontWeight: 500 }}
                      >
                        <li>
                          <a href="#">
                            <Popover content={Usermenu} placement="bottomRight">
                              <StyledInitialName>
                                {getInitialName(props.userProfile.name)}
                              </StyledInitialName>
                            </Popover>
                          </a>
                        </li>
                      </ul>
                    }
                    className="rightMenuLogout"
                    itemLayout="horizontal"
                    dataSource={analyticsdata}
                    renderItem={() => (
                      <List.Item>
                      </List.Item>
                    )}
                  />
                </ConfigProvider>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledExpandedHeader>
  );
};

NewHeader.propTypes = {
  properties: PropTypes.array.isRequired,
  orgId: PropTypes.string,
  propertyId: PropTypes.string,
  navigateByPropertyId: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  handleLogout: PropTypes.func.isRequired,
  onLogoClick: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      farmId: PropTypes.string,
      id: PropTypes.string.isRequired,
    }),
  }),
};
